/* ===================================================================== */
/* ===================================================================== */

/* ============= COLOR CONFIGURATION =========== */
:root {
  --primary-color: #e30613;
  --primary-color-opacity: rgba(227, 6, 19, 0.75);
  --secondary-color: #e30613;
}

/* ============= CSS PAGE UTILITIES =============== */
html, body{
  background: #fff; /* #f3f7f9 */
  overflow-x: hidden!important;
  font-family: Poppins;
}

a, a:hover, a:focus {
  text-decoration: none;
  color: var(--primary-color);
}

/* Change input autocomplete with white */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-animation: autofill 0s forwards;
  animation: autofill 0s forwards;
}

@keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

@-webkit-keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

:focus { outline: none; }

/* Correction for slick-sliders */
.slick-prev:before {
  content: '←'!important; /* \2190 */
}

.slick-next:before, [dir=rtl] .slick-prev:before {
  content: '→'!important; /* \2192 */
}

.slick-dots li button:before{
  content: '•'!important;
}

.react-contexify__item__content{
  width:100%;
  background: #2C3A4A!important;
  borderRadius:4px
}

.react-contexify__item__content:hover{
  background: #556c84!important;
}

.react-contexify__item react-contexify__item--disabled{
  background: #2C3A4A!important;
}

/* ===================================================================== */
/* **************************** ICONS UTILS **************************** */
/* ===================================================================== */

/* ============= SOCIAL COLOR =============== */
.fab{
  color: #ababab;
  transition-duration:0.4s;
  cursor:pointer;
}
.facebook-color:hover{
  color: #3b5998;
}

.twitter-color:hover{
  color: #1dcaff;
}

.instagram-color:hover{
  color: #f8673d;
}

/* ===================================================================== */
/* **************************** VIDEO PLAYER *************************** */
/* ===================================================================== */
.video-react-big-play-button{
  position: absolute!important;
  top: calc(50% - 20px)!important;
  left: calc(50% - 50px)!important;
}

.video-react{
  height: 80vh
}

/* ===================================================================== */
/* **************************** ANIMATIONS ***************************** */
/* ===================================================================== */

/* =========== OFFLINE ANIMATION ============ */

.offline-animation{
  animation: offlineAnimation 3s;
  animation-direction: alternate;
  -webkit-animation-name: offlineAnimation;
  animation-name: offlineAnimation;
  -webkit-filter: grayscale(100%);
}

@-webkit-keyframes offlineAnimation {
  0% {
    -webkit-filter: grayscale(0%);
    -webkit-transform: scale(1.0);
  }
  50% {
    -webkit-filter: grayscale(50%);
    -webkit-transform: scale(1.25);
  }
  100% {
    -webkit-filter: grayscale(100%);
    -webkit-transform: scale(1);
  }
}

.badge-notfication {
  display: inline-block;
  background-color: lighten(red, 20%);
  border-radius: 50%;
  color: #fff;
  padding: 0.5em 0.75em;
  position: relative;
}

.badge-animation {
  content: '';
  display: block;
  animation: pulse 2s ease infinite;
  border-radius: 50%;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}